import React, { useState } from "react";
import { useForm } from "react-hook-form";


// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import DefaultAuth from './../layout/Default';

import Logger from '../../../components/utils/logger'
import useAuth from "../components/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
const logger = new Logger('ReqestPasswordReset');

const illustration = `${process.env.PUBLIC_URL}/assets/img/auth/droplla-side-banner.png`;

function RequestPasswordReset() {
  const textColor = useColorModeValue("navy.700", "white");

  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const { checkSignInStatus } = useAuth();

  const navigate = useNavigate();

  checkSignInStatus();

  const location = useLocation();
  const invalidToken = location.state?.invalidToken;

  const textColorSecondary = invalidToken ? "red.500" : "subtleGray.500";

  const message = invalidToken
    ? "The reset link is not longer valid. Please re-enter your email to receive a new link."
    : "Enter the email associated with your droplla account, and we'll send you a link to reset your password.";


  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmissionError(null);

    const payload = {
      emailAddress: data.email,
    };


    try {
      const response = await fetch('/api/auth/email-profile/password-forgotten', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 204) {
        navigate('/request-password-reset-success', { state: { message: `${data.email}` } });
      } else {
        setSubmissionError("Oops! That didn't go through. Please try again in a moment.");
      }
    } catch (error) {
      logger.error(error);
      setSubmissionError("Oops! That didn't go through. Please try again in a moment.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Reset Password
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            w={{ base: "100%", md: "420px" }}
            color={textColorSecondary}
            fontWeight='400'
            fontSize='lg'
            flexWrap="wrap"
          >
          {message}
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.email} isRequired>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                htmlFor='email'
              >
                Email
              </FormLabel>
              <Input
                variant='droplla'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='Required'
                mb={1}
                size='lg'
                id='email'
                {...register('email', {
                  required: 'Email is required',
                  pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email address' }
                })}
                autoComplete="email"
              />
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.email && errors.email.message}</FormErrorMessage>
              </Box>
            </FormControl>

            {submissionError && (
              <Text color="red.500" mb={4} fontSize="xs">
                {submissionError}
              </Text>
            )}

            <Button
              w='100%'
              h='50'
              mb='24px'
              variant="droplla"
              type="submit"
              mt={2}
              isLoading={isSubmitting}
            >
              Send Email
            </Button>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default RequestPasswordReset;
