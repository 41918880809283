import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Helmet } from 'react-helmet-async';


// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import DefaultAuth from './../layout/Default';

// Assets
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import Logger from '../../../components/utils/logger'
import useAuth from "../components/useAuth";
import { logloginAttempt } from "components/utils/analytics";
const logger = new Logger('SignIn');

const illustration = `${process.env.PUBLIC_URL}/assets/img/auth/droplla-side-banner.png`;

function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "subtleGray.500";
  const textColorBrand = useColorModeValue("droplla.500", "white");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAlreadySignedIn, setIsAlreadySignedIn] = useState(false);
  const [firstMount, setFirstMount] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const { signIn, checkSignInStatus } = useAuth();

  useEffect(() => {
    async function checkStatus() {
      const isSignedIn = await checkSignInStatus();

      if (isSignedIn) {
        setIsAlreadySignedIn(true);
      } else {
        setIsAlreadySignedIn(false);
      }
    }
    if (!firstMount) {
      checkStatus();
      setFirstMount(true);
    }
  }, [checkSignInStatus, firstMount]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmissionError(null);

    const payload = {
      emailAddress: data.email,
      password: data.password,
    };

    let response;

    try {
      response = await fetch('/api/auth/email-profile/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 204) {
        logloginAttempt("success", response.status)
      } else {
        logloginAttempt("failure", response.status)
      }


      if (response.status === 204) {
        const tokenExpiry = response.headers.get('X-Token-Expiry');
        signIn(true, tokenExpiry)
      } else if (response.status === 401) {
        setSubmissionError('The email or password you entered is incorrect.');
      } else {
        logger.error('Unhandled response.status: ' + response.status)
        setSubmissionError("Oops! That didn't go through. Please try again in a moment.");
      }
    } catch (error) {
      logloginAttempt("failure", "unknown")
      logger.error(error)
      setSubmissionError("Oops! That didn't go through. Please try again in a moment.");
    } finally {
      if (response && response.status !== 204) {
        setIsSubmitting(false);
      } else if (!response) {
        setIsSubmitting(false);
      }
    }
  };

  if (isAlreadySignedIn) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner
          thickness="4px"
          speed="0.75s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
          mb={4}
        />
      </Flex>
    );
  }

  return (
    <>
      <Helmet>
        <title>Sign In - droplla</title>
        <meta name="description" content="Log in to your droplla account to access powerful dropshipping tools." />
        <link rel="canonical" href="https://www.droplla.com/sign-in" />
      </Helmet>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          flexDirection='column'
        >
          <Box me='auto'>
            <Heading as='h1' color={textColor} fontSize='36px' mb='10px'>
              Sign In
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='lg'
            >
              Enter your email and password to sign in
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors.email} isRequired>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='md'
                  fontWeight='500'
                  color='textColor'
                  htmlFor='email'
                >
                  Email
                </FormLabel>
                <Input
                  variant='droplla'
                  ms={{ base: "0px", md: "0px" }}
                  type='email'
                  placeholder='Required'
                  mb={1}
                  size='lg'
                  id='email'
                  {...register('email', {
                    required: 'Email is required',
                    pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email address' }
                  })}
                  autoComplete="email"
                />
                <Box mb={4}>
                  <FormErrorMessage fontSize="xs">{errors.email && errors.email.message}</FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl isInvalid={errors.password} isRequired>
                <FormLabel
                  ms='4px'
                  fontSize='md'
                  fontWeight='500'
                  color='textColor'
                  display='flex'
                  htmlFor='password'
                >
                  Password
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    placeholder='Required'
                    mb={0}
                    size='lg'
                    type={show ? "text" : "password"}
                    variant='droplla'
                    id='password'
                    {...register('password', { required: 'Password is required' })}
                    autoComplete="current-password"
                  />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                <Box mb={4}>
                  <FormErrorMessage fontSize="xs">{errors.password && errors.password.message}</FormErrorMessage>
                </Box>
              </FormControl>

              {submissionError && (
                <Text color="red.500" mb={4} fontSize="xs">
                  {submissionError}
                </Text>
              )}

              <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='start'
                maxW='100%'
                mt='0px'
              >
                <Text
                  mb={6}
                  mt={-2}
                  ms='4px'
                  color={textColorSecondary}
                  fontWeight='400'
                  fontSize='sm'
                >
                  <Link to='/request-password-reset'>
                    <Text
                      color={textColorBrand}
                      as='span'
                      ms='5px'
                      fontWeight='500'
                    >
                      Forgot Passport?
                    </Text>
                  </Link>
                </Text>
              </Flex>
              <Button
                w='100%'
                h='50'
                mb='24px'
                variant="droplla"
                type="submit"
                mt={2}
                isLoading={isSubmitting}
              >
                Sign In
              </Button>
            </form>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'
            >
              <Text
                mb={8}
                ms='4px'
                color={textColorSecondary}
                fontWeight='400'
                fontSize='md'
              >
                Not registered yet?
                <Link to='/sign-up'>
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'
                  >
                    Create an Account
                  </Text>
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default SignIn;
